import request from '@/utils/request'

export const login = (query) => {
  return request({
    url    : 'anim/login',
    method : 'post',
    data   : query
  })
}

// Local端搭配devServer測試用
export const loginProcess = () => {
  return request({
    baseURL : `${ process.env.VUE_APP_API_URL }`,
    url     : '/api/loginProcess',
    method  : 'get'
  })
}

export const logout = () => {
  return request({
    url    : 'merchant/logout',
    method : 'post'
  })
}

// 取得使用者資訊
export const userInfo = () => {
  return request({
    url    : '/merchant/loginInfo',
    method : 'get'
  })
}
