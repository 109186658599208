import { routeMiddleware } from '@/utils/middleware'
import { i18nInstance } from '@/plugins/i18n.js'

let i18n = i18nInstance()

export default [
  {
    path        : '/',
    redirect    : '/project',
    component   : () => import('@/layout/admin.vue'),
    meta        : { title : 'Home' },
    beforeEnter : routeMiddleware,
    children    : [
      {
        name      : 'dashboard',
        path      : '/dashboard',
        component : () => import('@/views/Dashboard/Dashboard.vue')
      },
      {
        name        : 'project',
        path        : '/project',
        meta        : { title : i18n.global.t('admin.project.pageName') },
        beforeEnter : routeMiddleware,
        component   : () => import('@/views/Project/Project.vue')
      },
      {
        name        : 'animate',
        path        : '/animate',
        meta        : { title : i18n.global.t('admin.animate.pageName') },
        beforeEnter : routeMiddleware,
        component   : () => import('@/views/Animate/index.vue')
      },
      {
        name        : 'manual',
        path        : '/manual',
        meta        : { title : i18n.global.t('admin.manual.pageName') },
        beforeEnter : routeMiddleware,
        component   : () => import('@/views/Manual/Manual.vue')
      }
    ]
  }
]
