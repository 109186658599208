const getters = {
  isShowStatistics (state) {
    return state.animate.projectSetting.current.addon.includes('match_stats')
  },
  isShowSwiper (state) {
    return state.animate.projectSetting.current.carousel.length > 0
  },
  background (state) {
    return state.animate.projectSetting.current.background
  },
  venue (state) {
    return state.animate.projectSetting.current.venue
  },
  carousel (state) {
    return state.animate.projectSetting.current.carousel
  },
  canvasWidth (state) {
    return state.layout.canvasWidth
  },
  canvasHeight (state) {
    return state.layout.canvasHeight
  },
  canvasRatioScale (state) {
    return state.layout.canvasRatioScale
  }
}
export default getters
