import { createStore } from 'vuex'
import user from './modules/user'
import layout from './modules/layout'
import match from './modules/match'
import snackbar from './modules/snackbar'
import animate from './modules/animate'
import getters from './getters'

export default createStore({
  modules : {
    user,
    layout,
    match,
    snackbar,
    animate
  },
  getters
})
