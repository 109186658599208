export default {
  football : {
    stat : {
      trap            : '控球',
      possession      : '控球率',
      attack          : '進攻',
      dangerousAttack : '危險進攻',
      shotsOnTarget   : '射正',
      shotsOffTarget  : '射偏',
      penaltyKick     : '點球',
      freeKick        : '任意球',
      cornerKick      : '角球',
      goalKick        : '球門球',
      offside         : '越位',
      throwIn         : '界外球',
      shoot           : '射門',
      goal            : '進球',
      ownGoal         : '烏龍球',
      substitution    : '替補',
      redCard         : '紅牌',
      yellowCard      : '黃牌',
      secondYellow    : '兩黃一紅',
      injury          : '傷停'
    },
    swiper : {
      vs          : '對陣',
      stat        : '賽況',
      lineup      : '陣容',
      battle      : '交鋒',
      odds        : '指數',
      asia        : '亞',
      europe      : '歐',
      sizesBalls  : '大',
      first       : '初',
      last        : '即',
      immediate   : '即',
      win         : '勝',
      draw        : '平',
      lose        : '負',
      winningRate : '贏盤率',
      battleTxt   : '近{0}場歷史交鋒'
    },
    type : {
      NoAnimation     : '暫無動畫',
      coming          : '即將開始',
      notStarted      : '未開賽',
      firstHalf       : '上半場',
      secondHalf      : '下半場',
      fullTime        : '比賽結束',
      postponed       : '比賽延期',
      interruption    : '比賽中斷',
      cut             : '比賽腰斬',
      cancel          : '比賽取消',
      error           : '比賽異常',
      undetermined    : '比賽待定',
      kickOff         : '開球',
      midfied         : '中場',
      extraTime       : '加時',
      injuryTime      : '傷停補時',
      penaltyShootout : '點球對決',
      reviewRed       : '視頻裁判正在覆審紅牌',
      reviewGoal      : '視頻裁判正在覆審進球',
      reviewPenalty   : '視頻裁判正在覆審點球'
    },
    position : {
      FC  : '前鋒',
      ST  : '影鋒',
      AMC : '前腰',
      AML : '左前衛',
      AMR : '右前衛',
      MC  : '中前衛',
      ML  : '左前腰',
      MR  : '右前腰',
      WBL : '左後衛',
      WBR : '右後衛',
      DC  : '中後衛',
      DL  : '左後衛',
      DR  : '右後衛',
      DM  : '後腰',
      SW  : '清道夫',
      GK  : '守門員'
    }
  }
}
