<template>
  <v-snackbar v-model="snackbar.visible"
              :timeout="snackbar.timeout"
              :color="snackbar.color"
              height="60px"
              location="top"
              :close-on-back="false"
              timer="true">
    {{ snackbar.message }}
    <template v-slot:actions>
      <v-btn color="white"
             variant="text"
             @click="snackbar.visible = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup>
  import { reactive, watch } from 'vue'
  import { useStore } from 'vuex'

  const store = useStore()

  const snackbar = reactive({
    visible : false,
    timeout : 3000,
    color   : 'success',
    message : ''
  })

  watch(() => store.state.snackbar.snackbar, (newSnackbar) => {
      snackbar.visible = false
      setTimeout(() => {
        Object.assign(snackbar, newSnackbar)
      }, 10)
    })
</script>
