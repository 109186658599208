import admin from './admin'
import common from './common'
import basketball from './basketball'
import football from './football'

export default {
  ...admin,
  ...common,
  ...basketball,
  ...football
}
