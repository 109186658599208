export default {
  //籃球技術統計-狀態碼
  BBT_CODE_THREE_POINTS        : 1, // 3分球得分數
  BBT_CODE_TWO_POINTS          : 2, // 2分球得分數
  BBT_CODE_FREETHROW_POINTS    : 3, // 罰球得分
  BBT_CODE_TIMEOUT             : 4, // 暫停
  BBT_CODE_FOUL                : 5, // 犯規數
  BBT_CODE_FREETHROW_PERCENTAG : 6, // 罰球命中率

  //籃球比賽狀態-狀態碼
  BBZT_CODE_UNUSUAL         : '0', //比賽異常
  BBZT_CODE_NO_START        : '1', //比賽未開始
  BBZT_CODE_FIRST_ING       : '2', //第一節進行中
  BBZT_CODE_FIRST_END       : '3', //第一節結束
  BBZT_CODE_SECOND_ING      : '4', //第二節進行中
  BBZT_CODE_SECOND_END      : '5', //第二節結束
  BBZT_CODE_THIRD_ING       : '6', //第三節進行中
  BBZT_CODE_THIRD_END       : '7', //第三節結束
  BBZT_CODE_FOURTG_ING      : '8', //第四節進行中
  BBZT_CODE_OVERTIME_ING    : '9', //加時
  BBZT_CODE_END             : '10', //比賽完場
  BBZT_CODE_BREAK_OFF       : '11', //比賽中斷
  BBZT_CODE_CANCEL          : '12', //比賽取消
  BBZT_CODE_DEFER           : '13', //比賽延期
  BBZT_CODE_WAIST_CUT       : '14', //比賽腰斬
  BBZT_CODE_UNDETERMINED    : '15', //比賽待定
  BBZT_CODE_OVERTIME_SECOND : '16', //第二加時
  BBZT_CODE_OVERTIME_THIRD  : '17', //第三加時
  BBZT_CODE_ONE_HARF        : '18', //上半場
  BBZT_CODE_SECOND_HARF     : '19', //下半場
  BBZT_CODE_HARF_MATCH      : '20', //比賽中場

  //籃球事件碼
  BBE_POSSESION         : '101', //持有球權
  BBE_FREE_THROW        : '102', //罰球
  BBE_FREE_THROW_HIT    : '103', //罰球得分
  BBE_FREE_THROW_MISS   : '104', //罰球未中
  BBE_TIME_OUT          : '111', //暫停
  BBE_SECTION_END       : '112', //賽節結束
  BBE_HARF_END          : '113', //半場結束
  BBE_GAME_END          : '114', //比賽結束
  BBE_OVERTIME          : '115', //加時賽
  BBE_FOUL              : '116', //犯規
  BBE_SECTION_FIRST     : '117', //第一節
  BBE_SECTION_SECOND    : '118', //第二節
  BBE_SECTION_THIRD     : '119', //第三節
  BBE_SECTION_FORTH     : '120', //第四節
  BBE_FIRST_HALF        : '121', //上半場
  BBE_SECOND_HALF       : '122', //下半場
  BBE_TWO_POINTS_HIT    : '123', //倆分投中
  BBE_TWO_POINTS_MISS   : '124', //倆分未中
  BBE_THREE_POINTS_HIT  : '125', //三分投中
  BBE_THREE_POINTS_MISS : '126' //三分未投中
}
