const CANVAS_BASE_WIDTH = 1200

const layout = {
  state : {
    drawer            : true,
    previewBlockWidth : 1200, // 預覽區域的寬度
    ratioHeightWidth  : 9 / 16, // canvas 寬高比例 16:9
    canvasWidth       : 1200,
    canvasHeight      : 675,
    canvasRatioScale  : 1
  },

  mutations : {
    handleDrawer (state, payload) {
      state.drawer = payload
    },
    setPreviewBlockWidth (state, payload) {
      state.previewBlockWidth = payload
    },
    setCanvasWidth (state, payload) {
      state.canvasWidth = payload
    },
    setCanvasHeight (state, payload) {
      state.canvasHeight = payload
    },
    setCanvasRatioScale (state, payload) {
      state.canvasRatioScale = payload
    }
  },

  actions : {
    onChangePreviewBlockWidth ({ commit }, width) {
      commit('setPreviewBlockWidth', width)
    },

    onChangeCanvasWidth ({ commit, state }, width) {
      commit('setCanvasWidth', width)

      // update height
      const height = state.ratioHeightWidth * width
      commit('setCanvasHeight', height)

      // update ratioScale
      const scale = width / CANVAS_BASE_WIDTH
      commit('setCanvasRatioScale', scale)
    }
  }
}

export default layout
