export default {
  appName     : '动画管理后台',
  globalError : '发生错误，请联络技术人员！',
  login       : {
    merchantCode : '商户代码',
    account      : '帐号',
    password     : '密码',
    login        : '登录'
  },
  redirect : {
    errorMessageTitle : '发生错误！',
    toLoginMessage    : '按下确定跳转至登入页面。'
  },
  header : {
    language : {
      tw : '繁體中文',
      cn : '简体中文',
      en : 'English'
    },
    logout : '登出'
  },
  formTemplate : {
    upload       : '上传档案',
    reset        : '重填',
    submit       : '确定送出',
    input        : '输入',
    choose       : '选择',
    beforeRemove : '确定移除',
    handleExceed : '最多上传一张,请先删除上一选项',
    error        : '失败',
    cancel       : '取消'
  },
  form : {
    required            : '「{field}」为必填。',
    emailRequireMessage : '请输入email',
    emailFormatMessage  : 'email 格式错误',
    emailPlaceholder    : '请输入email',
    buttonConfirm       : '确定',
    search              : '搜寻',
    clear               : '清除',
    back                : '返回'
  },
  table : {
    number  : '编号',
    appName : '名称',
    status  : '状态',
    operat  : {
      columnName : '操作',
      manage     : '管理',
      edit       : '编辑',
      delete     : '删除',
      recovery   : '復原'
    },
    noData : '无资料...'
  },
  errorPage : {
    403 : {
      p1 : '您没有权限访问该页面',
      p2 : '返回首页',
      p3 : '返回上一页'
    },
    404 : {
      p1 : '您所访问的页面不存在',
      p2 : '返回首页',
      p3 : '返回上一页'
    }
  },
  dashboard : {
    greet : '欢迎登入系统，请点选左侧任一选单开始！'
  },
  admin : {
    project : {
      pageName : '专案列表',
      table    : {
        name        : '专案名称',
        projectCode : '专案代码',
        tag         : '专案Tag',
        domain      : '域名'
      }
    },
    animate : {
      pageName       : '动画设定',
      animatePreview : {
        project               : '专案',
        projectPlaceHolder    : '请选择专案',
        sportTypes            : '球种',
        sportTypesPlaceHolder : '请选择球种',
        effectPreview         : '效果预览'
      },
      animateEditor : {
        animateSetting : '动画参数设定',
        default        : '恢复预设设定',
        cancel         : '取消',
        save           : '储存',
        params         : {
          carousel : {
            match_info   : '对阵资讯',
            match_stats  : '赛况统计',
            lineup       : '比赛阵容',
            team_history : '历史交锋',
            odds         : '盘口指数'
          },
          addon : {
            match_trend : '比赛趋势',
            match_stats : '赛况统计'
          }
        }
      }
    },
    manual : {
      pageName     : '使用说明及代码列表',
      instructions : {
        name              : '使用说明',
        desc              : '动画 URL 需自行组装。',
        assembleMethod    : '组装方式：https://{域名}/v4/语系/球种/cartoon/matchId/宽度(px)?{自定义参数}=xxx。',
        example           : '示例： https://anim.demo.com/v4/zh_TW/cartoon/10001/512?home=xxx',
        type              : '球种：请参考代码列表。',
        locale            : 'lang：语系，请参考代码列表。',
        matchId           : 'matchId：有提供动画资料的比赛ID，可由 API 获取。',
        width             : 'width：自定义宽度。未带入时会自适应萤幕宽度。',
        customNameAndIcon : '自定义名称及图示：请参考代码列表。'
      },
      codeList : {
        name : '代码列表',
        lang : {
          name  : '语系',
          desc  : '如需开启语系使用权限，请洽商务人员。',
          param : '参数',
          zh_TW : '繁体中文',
          zh_CN : '简体中文',
          en_US : '英文'
        },
        sportType : {
          name       : '球种',
          desc       : '如需开启球种使用权限，请洽商务人员。',
          param      : '参数',
          football   : '足球',
          basketball : '篮球'
        },
        custom : {
          name       : '自定义名称及图示',
          desc1      : '「自定义名称」及「图示」为选填，可以自行排列组合。',
          desc2      : '如未带入则显示敝司预设的名称及图示。',
          item       : '项目',
          param      : '参数',
          home       : '主队名称',
          away       : '客队名称',
          league     : '联赛名称',
          homeicon   : '主队图示',
          awayicon   : '客队图示',
          leagueicon : '联赛图示'
        }
      }
    }
  },
  sport : {
    football : {
      name : '足球'
    },
    basketball : {
      name : '篮球'
    }
  }
}
