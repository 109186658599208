export default {
  appName     : '動畫管理後台',
  globalError : '發生錯誤，請聯絡技術人員！',
  login       : {
    merchantCode : '商戶代碼',
    account      : '帳號',
    password     : '密碼',
    login        : '登入'
  },
  redirect : {
    errorMessageTitle : '發生錯誤！',
    toLoginMessage    : '按下確定跳轉至登入頁面。'
  },
  header : {
    language : {
      tw : '繁體中文',
      cn : '简体中文',
      en : 'English'
    },
    logout : '登出'
  },
  formTemplate : {
    upload       : '上傳檔案',
    reset        : '重填',
    submit       : '確定送出',
    input        : '輸入',
    choose       : '選擇',
    beforeRemove : '確定移除',
    handleExceed : '最多上傳一張,請先刪除上一選項',
    error        : '失敗',
    cancel       : '取消'
  },
  form : {
    required            : '「{field}」為必填。',
    emailRequireMessage : '請輸入email',
    emailFormatMessage  : 'Email 格式錯誤',
    emailPlaceholder    : '請輸入email',
    imageSizeLimitMb    : '圖片不得超過{limit}mb。',
    buttonConfirm       : '確定',
    search              : '搜尋',
    clear               : '清除',
    back                : '返回'
  },
  table : {
    number  : '編號',
    appName : '名稱',
    status  : '狀態',
    operat  : {
      columnName : '操作',
      manage     : '管理',
      edit       : '編輯',
      delete     : '刪除',
      recovery   : '復原'
    },
    noData : '無資料...'
  },
  errorPage : {
    403 : {
      p1 : '您沒有權限訪問該頁面',
      p2 : '返回首頁',
      p3 : '返回上一頁'
    },
    404 : {
      p1 : '您訪問的頁面不存在！',
      p2 : '返回首頁',
      p3 : '返回上一頁'
    }
  },
  dashboard : {
    greet : '歡迎登入系統，請點選左側任一選單開始！'
  },
  admin : {
    project : {
      pageName : '專案列表',
      table    : {
        name        : '專案名稱',
        projectCode : '專案代碼',
        tag         : '專案Tag',
        domain      : '域名'
      },
      copyDomainSuccess : '複製成功！'
    },
    animate : {
      pageName       : '動畫設定',
      animatePreview : {
        project               : '專案',
        projectPlaceHolder    : '請選擇專案',
        sportTypes            : '球種',
        sportTypesPlaceHolder : '請選擇球種',
        effectPreview         : '效果預覽'
      },
      animateEditor : {
        animateSetting : '動畫參數設定',
        default        : '恢復預設設定',
        cancel         : '取消',
        save           : '儲存',
        preview        : {
          effect             : '預覽效果',
          match              : '選擇預覽比賽',
          matchPlaceHolder   : '請選擇比賽',
          lang               : '動畫預覽語系',
          langPlaceHolder    : '請選擇語系',
          langDesc           : '實際可使用語系請參考專案列表',
          size               : '顯示預覽尺寸',
          sizeResponsiveOpen : '開啟自適應',
          event              : '事件演出預覽',
          eventPlaceHolder   : '請選擇演出',
          eventDesc          : '選擇後，會停止顯示預覽比賽',
          eventGo            : '預覽事件'
        },
        style : {
          title     : '風格設定',
          fad01     : '賽前面板廣告欄位',
          fad01Desc : '建議比例 4:5:1',
          fad02     : '場邊廣告欄位',
          fad02Desc : '建議比例 7:6:1',
          fad03     : '浮水印',
          fad03Desc : '建議比例 1:1',
          bad01     : '賽前面板廣告欄位',
          bad01Desc : '建議比例 4:5:1',
          bad02     : '場邊廣告欄位',
          bad02Desc : '建議比例 7:6:1',
          bad03     : '浮水印',
          bad03Desc : '建議比例 1:1',
          xAxis     : 'X軸',
          yAxis     : 'Y軸',
          width     : '圖片原始寬度(此寬度以 1200 px為基準)',
          upload    : '上傳',
          dialog    : {
            disclaimer  : '免責聲明',
            imageUpload : '圖片上傳',
            agreeDesc   : '我閱讀並同意上述聲明內容',
            agreeWith   : '同意人',
            back        : '返回',
            next        : '下一步',
            setPreview  : '確認圖片'
          },
          disclaimer : {
            header : '當您勾選同意後，即表示您已詳細閱讀及明確瞭解動畫管理後台（以下稱「本服務」）之服務條款、隱私權保護政策，並同意在下列情況時本服務毋需承擔任何責任：',
            paragraphA :
              'A、您使用「本服務」之風險由您個人負擔。用戶同意使用「本服務」各項服務係基於用戶的個人意願，並同意自負任何風險，包括因為自「本服務」下載資料或圖片，或自「本服務」服務中獲得之資料導致發生任何資源流失等結果。',
            paragraphB : 'B、「本服務」不負任何明示或默示之擔保責任。「本服務」不保證各項服務之穩定、安全、無誤、及不中斷； 用戶明示承擔使用「本服務」之所有風險及可能致生之任何損害。',
            paragraphC : 'C、用戶在「本服務」上填寫的個人資料、刊登資訊、上傳照片等行為，純屬用戶個人行為，「本服務」對其內容之真實性或完整性不負有任何責任。',
            paragraphD :
              'D、對於透過「本服務」銷售之物件或其他服務，「本服務」對其交易過程及物件/服務本身，均不負任何瑕疵擔保責任。用戶瞭解您透過「本服務」所購得之物件或服務，完全由物件或服務提供人負全責，若有任何瑕疵或擔保責任，均與「本服務」無關。',
            paragraphE : 'E、「本服務」僅收取會員之廣告刊登費為用戶使用本站交易平台及本站客服在有限的範圍內協調交易等費用，不包含交易擔保費用。',
            paragraphF : 'F、由於與「本服務」連結的其他網站所造成之個人資料洩露及由此而導致的任何法律爭議和後果均與「本服務」無關，概由用戶自行承擔法律責任。',
            paragraphG : `G、「本服務」上刊登的個人廣告、商業廣告及各種商品的促銷資訊，除非屬於本站所自行出售，
              其內容均係由該個人、廣告商或商品、服務提供人所為，因此產生的糾紛本網站概不負責，「本服務」僅係提供刊登的媒介。`,
            paragraphH : `H、「本服務」服務受限於用戶之介接電路或網際網路頻寬及用戶終端設備之品質等因素之影響而有所差異，用戶應確保其自行租用之專線電路、寬頻上網之電路或網路或電話電路等正常運作。
              用戶同意「本服務」無須對電路及網路之連接品質或傳輸速率等、用戶所擷取資訊資源之正確性與完整及用戶主機運算效能等負責，「本服務」亦毋庸因上開任何因素直接或間接所致「本服務」服務無法使用負任何責任。用戶之系統如發生遭第三人經由網際網路入侵、破壞或擷取其資料等侵害情形，因此所生直接或間接之損失，「本服務」不負賠償責任。`,
            paragraphI : `I、如遇電信事業系統發生障礙，「本服務」將依電信事業官方公告之服務層級協議協助用戶儘速排除障礙。
              如因系統或設備障礙、阻斷等情形，以致發生錯誤、遲滯、中斷或不能傳遞而無法提供「本服務」服務造成損害時，其所生之損害，除按電信事業官方公告外，「本服務」不負任何損害賠償責任。任何非「本服務」提供之第三方軟體服務異常而造成「本服務」服務中斷時，「本服務」均毋庸負任何責任。`,
            paragraphJ : `J、在無可避免的主機維修、升級、更新、無法控制的因素，及需要重新提供「本服務」服務內容，「本服務」均依電信事業官方所提供之訊息，於暫停主機運作前，通知用戶並協助用戶保持服務之正常情形。
              暫停服務期間或因必要之測試檢修，而造成不能正常運行時，用戶同意「本服務」毋庸負擔任何責任。`,
            paragraphK : 'K、對於用戶透過「本服務」發佈虛假資訊、及欺騙、敲詐行為者，純屬用戶個人行為，「本服務」對因此而產生的一切糾紛不負有任何責任。特此聲明。',
            footerA :
              '「本服務」僅係提供用戶刊登媒介之使用，嚴禁任何未經授權之商業行為、法令禁止行為、違反公共秩序或善良風俗行為及其他不符合本服務使用目的之行為，凡用戶有上開不法行為應自負法律責任，概予「本服務」無涉。',
            footerB :
              '如「本服務」有認為不適當之行為，「本服務」得依實際執行情形，增刪、修改或終止相關活動，並選擇最適方式告知用戶；當用戶違反相關法律規定致「本服務」或其關係企業、受僱人、受託人、代理人及其他相關履行輔助人因此受有損害或支出費用時，用戶應負擔損害賠償責任或填補其費用。'
          }
        },
        params : {
          title     : '參數設定',
          selectAll : '全選',
          cancelAll : '取消全選',
          carousel  : {
            title        : '數據輪播顯示',
            match_info   : '對陣資訊',
            match_stats  : '賽況統計',
            lineup       : '比賽陣容',
            team_history : '歷史交鋒',
            odds         : '盤口指數'
          },
          addon : {
            title       : '附加數據顯示',
            desc        : '會呈現在動畫版面下方',
            match_trend : '比賽趨勢',
            match_stats : '賽況統計'
          }
        }
      }
    },
    manual : {
      pageName     : '使用說明及代碼列表',
      instructions : {
        name              : '使用說明',
        desc              : '動畫 URL 需自行組裝。',
        assembleMethod    : '組裝方式：https://[域名]/[球種]/[matchId]?width=1024&[自定義參數]=xxx。',
        example           : '示例： https://anim.demo.com/10001?width=1024&lang=en_US',
        type              : '球種：請參考代碼列表。',
        locale            : 'lang：語系，請參考代碼列表。',
        matchId           : 'matchId：有提供動畫資料的比賽ID，可由 API 獲取。',
        width             : 'width：動畫畫面寬度(px)。未帶入時會自適應螢幕寬度。',
        customNameAndIcon : '自定義名稱及圖示：請參考代碼列表。'
      },
      codeList : {
        name : '代碼列表',
        lang : {
          name  : '語系',
          desc  : '如需開啟語系使用權限，請洽商務人員。',
          param : '參數',
          zh_TW : '繁體中文',
          zh_CN : '簡體中文',
          en_US : '英文'
        },
        sportType : {
          name       : '球種',
          desc       : '如需開啟球種使用權限，請洽商務人員。',
          param      : '參數',
          football   : '足球',
          basketball : '籃球'
        },
        custom : {
          name       : '自定義名稱及圖示',
          desc1      : '「自定義名稱」及「圖示」為選填，可以自行排列組合。',
          desc2      : '如未帶入則顯示敝司預設的名稱及圖示。',
          item       : '項目',
          param      : '參數',
          home       : '主隊名稱',
          away       : '客隊名稱',
          league     : '聯賽名稱',
          homeicon   : '主隊圖示',
          awayicon   : '客隊圖示',
          leagueicon : '聯賽圖示'
        }
      }
    }
  },
  sport : {
    football : {
      name : '足球'
    },
    basketball : {
      name : '籃球'
    }
  }
}
