const snackbar = {
  state : {
    snackbar : {
      visible : false,
      timeout : 3000,
      color   : 'success',
      message : ''
    }
  },
  mutations : {
    SHOW_SNACKBAR (state, payload) {
      state.snackbar = { ...state.snackbar, ...payload, visible : true }
    }
  },
  actions : {
    showSnackbar ({ commit }, payload) {
      commit('SHOW_SNACKBAR', payload)
    }
  }
}

export default snackbar
