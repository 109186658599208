export default {
  noData        : '暂无数据',
  loading       : '加载中',
  playbackPause : '回放暂停',
  team          : {
    home     : '主',
    away     : '客',
    homeTeam : '主队',
    awayTeam : '客队'
  },
  match : {
    min        : '分钟',
    leagueName : '联赛名称',
    cartoon    : '比赛动画',
    video      : '比赛直播',
    vs         : 'VS',
    win        : '胜',
    draw       : '平',
    lose       : '负',
    textLive   : '文字直播',
    status     : {
      notStarted : '未开赛',
      finished   : '比赛结束',
      scheduled  : '赛程'
    },
    stat : {
      foul  : '犯规',
      pause : '暂停',
      tips  : '提示'
    }
  }
}
