export default {
  // 足球事件碼Event
  FBE_KICK_OFF       : 124, // 開球
  FBE_FIRST_HALF     : 125, // 半場
  FBE_SECOND_HALF    : 126, // 下半場
  FBE_FULL           : 127, // 全場
  FBE_OVERTIME       : 128, // 加時賽
  FBE_OVERTIME_HALF  : 129, // 加時賽半場
  FBE_OVERTIME_FULL  : 131, // 加時賽全場
  FBE_GOAL_IN        : 101, // 進球
  FBE_GOAL_IN2       : 114, // 進球
  FBE_SHOT_IN        : 121, // 射正球門
  FBE_SHOT_OUT       : 122, // 射偏球門
  FBE_PENALTY_VS     : 132, // 點球對決
  FBE_PENALTY_KICK   : 103, // 點球
  FBE_PENALTY_IN     : 119, // 點球得分
  FBE_PENALTY_OUT    : 102, // 射失點球
  FBE_PENALTY_OUT2   : 133, // 射失點球
  FBE_ATK            : 112, // 進攻
  FBE_ATK2           : 142, // 進攻
  FBE_ATK_DANGER     : 111, // 危險進攻
  FBE_ATK_DANGER2    : 141, // 危險進攻
  FBE_TRAP           : 113, // 控球
  FBE_TRAP2          : 143, // 控球
  FBE_CORNER_KICK    : 115, // 角球
  FBE_GOAL_KICK      : 118, // 球門球
  FBE_GOAL_KICK2     : 144, // 球門球
  FBE_OUTSIDE_KICK   : 134, // 界外球
  FBE_FREE_KICK      : 138, // 危險任意球
  FBE_CARD_YELLOW    : 116, // 黃牌
  FBE_CARD_RED       : 117, // 紅牌
  FBE_SUBSTITUTION   : 123, // 替補
  FBE_SUBSTITUTION2  : 140, // 替補
  FBE_INJURED        : 135, // 受傷
  FBE_STOPPAGE_TIME  : 136, // 傷停補時
  FBE_OFFSIDE        : 137, // 越位
  FBE_JUDGMENT       : 145, // 視頻裁判判決中
  FBE_REVIEW_RED     : 146, // 視頻裁判正在覆審紅牌
  FBE_REVIEW_IN      : 147, // 視頻裁判正在覆審進球
  FBE_REVIEW_PENALTY : 148, // 視頻裁判正在覆審點球

  // 足球比賽狀態Status
  FBS_ERROR        : 0, // 比賽異常，說明：暫未判斷具體原因的異常比賽，可能但不限於：腰斬、取消等等，可隱藏處理	足球比賽狀態
  FBS_NOT_START    : 1, // 未開賽
  FBS_FIRST_HALF   : 2, // 上半場
  FBS_MIDFIELD     : 3, // 中場
  FBS_SECOND_HALF  : 4, // 下半場
  FBS_OVERTIME     : 5, // 加時賽
  FBS_OVERTIME2    : 6, // 加時賽(棄用)
  FBS_PENALTY_VS   : 7, // 點球決戰
  FBS_AFTER_GAME   : 8, // 完場
  FBS_DELAY        : 9, // 推遲
  FBS_SUSPEND      : 10, // 中斷
  FBS_KS           : 11, // 腰斬
  FBS_CANCEL       : 12, // 取消
  FBS_UNDETERMINED : 13, // 待定

  // 足球技術統計Technology
  FBT_GOAL_IN          : 1, // 進球
  FBT_CORNER_KICK      : 2, // 角球
  FBT_CARD_YELLOW      : 3, // 黃牌
  FBT_CARD_RED         : 4, // 紅牌
  FBT_OUTSIDE_KICK     : 5, // 界外球
  FBT_FREE_KICK        : 6, // 任意球
  FBT_GOAL_KICK        : 7, // 球門球
  FBT_PENALTY_KICK     : 8, // 點球
  FBT_SUBSTITUTION     : 9, // 換人
  FBT_START            : 10, // 比賽開始
  FBT_MIDFIELD         : 11, // 中場
  FBT_END              : 12, // 結束
  FBT_HALF_SCORE       : 13, // 半場比分
  FBT_YELLOW_TO_RED    : 15, // 兩黃變紅
  FBT_PENALTY_OUT      : 16, // 點球未進
  FBT_OWN_GOAL         : 17, // 烏龍球
  FBT_STOPPAGE_TIME    : 19, // 傷停補時
  FBT_SHOT_IN          : 21, // 射正
  FBT_SHOT_OUT         : 22, // 射偏
  FBT_ATK              : 23, // 進攻
  FBT_ATK_DANGER       : 24, // 危險進攻
  FBT_POSSESSION       : 25, // 控球率
  FBT_OVERTIME_END     : 26, // 加時賽結束
  FBT_PENALTY_KICK_END : 27 // 點球大戰結束
}
