const animate = {
  state : {
    projectId        : null,
    sportId          : null,
    responsiveStatus : true, // 是否開啟自適應模式
    projectSetting   : {
      originalAds : [],
      currentAds  : [],
      original    : {
        background   : '',
        venue        : '',
        menuLocation : '',
        carousel     : [],
        addon        : []
      },
      current : {
        background   : '',
        venue        : '',
        menuLocation : '',
        carousel     : [],
        addon        : []
      }
    }
  },

  mutations : {
    setProjectId (state, data) {
      state.projectId = data
    },
    setSportId (state, data) {
      state.sportId = data
    },
    setResponsiveStatus (state, data) {
      state.responsiveStatus = data
    },
    setOriginalAds (state, payload) {
      state.projectSetting.originalAds = payload
    },
    setCurrentAds (state, payload) {
      state.projectSetting.currentAds = payload
    },
    setOriginalProjectSetting (state, payload) {
      state.projectSetting.original = { ...payload }
    },
    setCurrentProjectSetting (state, payload) {
      state.projectSetting.current = { ...payload }
    }
  }
}

export default animate
