import FootballCode from '@/configs/codes/footballCode'
import BasketballCode from '@/configs/codes/basketballCode'

const matchInfo = {
  id                        : null,
  leagueId                  : null,
  leagueName                : null,
  leagueLogo                : null,
  matchTime                 : '',
  footballStartTime         : null,
  footballNextHalfStartTime : null,
  footballAddStartTime      : null,
  gameType                  : null,
  homeId                    : null,
  homeName                  : null,
  homeLogo                  : null,
  awayLineUps               : [],
  awayId                    : null,
  awayName                  : null,
  awayLogo                  : null,
  homeLineUps               : []
}

const matchStatus = {
  matchId    : null,
  gameType   : null,
  homeScore  : [],
  awayScore  : [],
  statusList : []
}

const footballAnalyzeData = {
  atk       : { home : 0, away : 0 },
  atkDanger : { home : 0, away : 0 },
  trap      : { home : 0, away : 0 },
  shotIn    : { home : 0, away : 0 },
  shotOut   : { home : 0, away : 0 },
  penalty   : { home : 0, away : 0 },
  corner    : { home : 0, away : 0 },
  yellow    : { home : 0, away : 0 },
  red       : { home : 0, away : 0 }
}

const basketballAnalyzeData = {
  threePoints     : { home : 0, away : 0 },
  twoPoints       : { home : 0, away : 0 },
  freeThrowPoints : { home : 0, away : 0 },
  timeOut         : { home : 0, away : 0 },
  foul            : { home : 0, away : 0 }
}

const match = {
  namespaced : true,

  state : {
    matchId               : null,
    matchInfo             : { ...matchInfo },
    matchStatus           : { ...matchStatus },
    score                 : { home : 0, away : 0 },
    footballAnalyzeData   : { ...footballAnalyzeData },
    basketballAnalyzeData : { ...basketballAnalyzeData }
  },

  mutations : {
    setMatchId (state, data) {
      state.matchId = data
    },
    setMatchInfo (state, data) {
      state.matchInfo = { ...state.matchInfo, ...data }
    },
    setMatchStatus (state, data) {
      state.matchStatus = { ...state.matchStatus, ...data }
    },
    setScore (state, data) {
      state.score = { ...state.score, ...data }
    },
    setFootballAnalyzeData (state, data) {
      state.footballAnalyzeData = { ...state.footballAnalyzeData, ...data }
    },
    setBasketballAnalyzeData (state, data) {
      state.basketballAnalyzeData = { ...state.basketballAnalyzeData, ...data }
    }
  },

  actions : {
    onUpdateMatchId ({ commit }, data) {
      commit('setMatchId', data)
    },

    onUpdateMatchInfo ({ commit }, data) {
      // 如果沒有資料, 就初始化 matchinfo
      commit('setMatchInfo', data || matchInfo)
    },

    onUpdateMatchStatus ({ commit }, data) {
      // 如果沒有資料, 就初始化 matchStatus
      commit('setMatchStatus', data || matchStatus)
    },

    onUpdateScore ({ commit }, data) {
      if (data.type == 'home') {
        commit('setScore', { home : data.score })
      } else {
        commit('setScore', { away : data.score })
      }
    },

    // 更新足球統計資料
    onUpdateFootballAnalyzeData ({ commit, state }, data = []) {
      const atk = data.find((item) => item.type == FootballCode.FBT_ATK) || state.footballAnalyzeData.atk
      const atkDanger = data.find((item) => item.type == FootballCode.FBT_ATK_DANGER) || state.footballAnalyzeData.atkDanger
      const trap = data.find((item) => item.type == FootballCode.FBT_POSSESSION) || state.footballAnalyzeData.trap
      const shotIn = data.find((item) => item.type == FootballCode.FBT_SHOT_IN) || state.footballAnalyzeData.shotIn
      const shotOut = data.find((item) => item.type == FootballCode.FBT_SHOT_OUT) || state.footballAnalyzeData.shotOut
      const penalty = data.find((item) => item.type == FootballCode.FBT_PENALTY_KICK) || state.footballAnalyzeData.penalty
      const corner = data.find((item) => item.type == FootballCode.FBT_CORNER_KICK) || state.footballAnalyzeData.corner
      const yellow = data.find((item) => item.type == FootballCode.FBT_CARD_YELLOW) || state.footballAnalyzeData.yellow
      const red = data.find((item) => item.type == FootballCode.FBT_CARD_RED) || state.footballAnalyzeData.red

      commit('setFootballAnalyzeData', { atk, atkDanger, trap, shotIn, shotOut, penalty, corner, yellow, red })
    },

    // 更新藍球統計資料
    onUpdateBasketBallAnalyzeData ({ commit, state }, data) {
      const threePoints = data.find((item) => item.type == BasketballCode.BBT_CODE_THREE_POINTS) || state.basketballAnalyzeData.threePoints
      const twoPoints = data.find((item) => item.type == BasketballCode.BBT_CODE_TWO_POINTS) || state.basketballAnalyzeData.twoPoints
      const freeThrowPoints = data.find((item) => item.type == BasketballCode.BBT_CODE_FREETHROW_POINTS) || state.basketballAnalyzeData.freeThrowPoints
      const timeOut = data.find((item) => item.type == BasketballCode.BBT_CODE_TIMEOUT) || state.basketballAnalyzeData.timeOut
      const foul = data.find((item) => item.type == BasketballCode.BBT_CODE_FOUL) || state.basketballAnalyzeData.foul

      commit('setBasketballAnalyzeData', { threePoints, twoPoints, freeThrowPoints, timeOut, foul })
    }
  }
}

export default match
