export default {
  stat : {
    trap            : '控球',
    possession      : '控球率',
    attack          : '进攻',
    dangerousAttack : '危险进攻',
    shotsOnTarget   : '射正',
    shotsOffTarget  : '射偏',
    penaltyKick     : '点球',
    freeKick        : '任意球',
    cornerKick      : '角球',
    goalKick        : '球门球',
    offside         : '越位',
    throwIn         : '界外球',
    shoot           : '射门',
    goal            : '进球',
    ownGoal         : '乌龙球',
    substitution    : '替补',
    redCard         : '红牌',
    yellowCard      : '黄牌',
    secondYellow    : '两黄一红',
    injury          : '伤停'
  },
  swiper : {
    vs          : '对阵',
    stat        : '赛况',
    lineup      : '阵容',
    battle      : '交锋',
    odds        : '指数',
    asia        : '亚',
    europe      : '欧',
    sizesBalls  : '大',
    first       : '初',
    last        : '即',
    immediate   : '即',
    win         : '胜',
    draw        : '平',
    lose        : '负',
    winningRate : '赢盘率',
    battleTxt   : '近{0}场历史交锋'
  },
  type : {
    NoAnimation     : '暂无动画',
    coming          : '即将开始',
    notStarted      : '未开赛',
    firstHalf       : '上半场',
    secondHalf      : '下半场',
    fullTime        : '比赛结束',
    postponed       : '比赛延期',
    interruption    : '比赛中断',
    cut             : '比赛腰斩',
    cancel          : '比赛取消',
    error           : '比赛异常',
    undetermined    : '比赛待定',
    kickOff         : '开球',
    midfied         : '中场',
    extraTime       : '加时',
    injuryTime      : '伤停补时',
    penaltyShootout : '点球对决',
    reviewRed       : '视频裁判正在复审红牌',
    reviewGoal      : '视频裁判正在复审进球',
    reviewPenalty   : '视频裁判正在复审点球'
  },
  position : {
    FC  : '前锋',
    ST  : '影锋',
    AMC : '前腰',
    AML : '左前卫',
    AMR : '右前卫',
    MC  : '中前卫',
    ML  : '左前腰',
    MR  : '右前腰',
    WBL : '左后卫',
    WBR : '右后卫',
    DC  : '中后卫',
    DL  : '左后卫',
    DR  : '右后卫',
    DM  : '后腰',
    SW  : '清道夫',
    GK  : '守门员'
  }
}
