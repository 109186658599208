import { createApp } from 'vue'
import { VueClipboard } from '@soerenmartius/vue3-clipboard'
import './assets/css/index.scss'

// ant-design-vue
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/reset.css'

import { loadFonts } from './plugins/webfontloader'
import installI18n from '@/plugins/i18n'
import vuetify from './plugins/vuetify'

import store from './store'
import router from './router'
import App from './App.vue'

let app = createApp(App)
installI18n(app)
loadFonts()

app.use(Antd)
app.use(store)
app.use(router)
app.use(VueClipboard)
app.use(vuetify)

app.mount('#app')
