import axios from 'axios'
import router from '../router/index'
import store from '../store'

const service = axios.create({
  baseURL : `${ process.env.VUE_APP_API_URL }/api/v1/`,
  timeout : 15000
})

service.interceptors.response.use((response) => {
    if (response.status !== 200) {
      return Promise.reject()
    }

    return response.data
  }, (error) => {
    if (error.response.status == 401) {
      store.dispatch('showSnackbar', {
        message : error.response.data.error.message,
        color   : 'error'
      })

      router.push('/login')
    }

    return Promise.reject({
      code    : error.response.data.error.code ?? error.response.status,
      message : error.response.data.error.message ?? error.response.data.error
    })
  })

export default service
