import { routeMiddleware } from '@/utils/middleware'

export default [
  {
    path      : '/login',
    name      : 'Login',
    component : () => import('@/views/Login/Login.vue')
  },
  {
    path      : '/404',
    name      : '404',
    component : () => import('@/views/ErrorPage/404.vue')
  }
]
