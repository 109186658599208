<template>
  <router-view />
  <Snackbar />
</template>

<script>
  import Snackbar from '@/components/Snackbar.vue'
  export default {
    components : {
      Snackbar
    }
  }
</script>

<style>
  @import './assets/css/main.css';
</style>
