export default {
  common : {
    noData        : '暫無數據',
    loading       : '加載中',
    playbackPause : '回放暫停',
    team          : {
      home     : '主',
      away     : '客',
      homeTeam : '主隊',
      awayTeam : '客隊'
    },
    match : {
      min        : '分鐘',
      leagueName : '聯賽名稱',
      cartoon    : '比賽動畫',
      video      : '比賽直播',
      vs         : 'VS',
      win        : '勝',
      draw       : '平',
      lose       : '負',
      textLive   : '文字直播',
      status     : {
        notStarted : '未開賽',
        finished   : '比賽結束',
        scheduled  : '賽程'
      },
      stat : {
        foul  : '犯規',
        pause : '暫停',
        tips  : '提示'
      }
    }
  }
}
