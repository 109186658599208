export default {
  basketball : {
    stat : {
      freeThrow      : '罰球',
      threePoints    : '3分',
      twoPoints      : '2分',
      attack         : '持球進攻',
      steal          : '搶斷',
      offcialTimeout : '官方暫停',
      twoPointsIn    : '兩分球投中',
      twoPointsOut   : '兩分球未進',
      threePointsIn  : '三分球投中',
      threePointsOut : '三分球未進',
      freeThrowIn    : '罰球命中',
      freeThrowOut   : '罰球偏出'
    },
    type : {
      NoAnimation    : '暫無動畫',
      coming         : '即將開始',
      notStarted     : '未開賽',
      firstHalf      : '上半場',
      secondHalf     : '下半場',
      fullTime       : '比賽結束',
      postponed      : '比賽延期',
      interruption   : '比賽中斷',
      cut            : '比賽腰斬',
      cancel         : '比賽取消',
      error          : '比賽異常',
      undetermined   : '比賽待定',
      midfied        : '中場',
      first          : '第一節',
      second         : '第二節',
      third          : '第三節',
      fourth         : '第四節',
      firstEnd       : '第一節完',
      secondEnd      : '第二節完',
      thirdEnd       : '第三節完',
      fourthEnd      : '第四節完',
      overTime       : '加時',
      secondOverTime : '第二加時',
      thirdOverTime  : '第三加時'
    },
    position : {}
  }
}
