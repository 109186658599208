export default {
  stat : {
    freeThrow      : '罚球',
    threePoints    : '3分',
    twoPoints      : '2分',
    attack         : '持球进攻',
    steal          : '抢断',
    offcialTimeout : '官方暂停',
    twoPointsIn    : '两分球投中',
    twoPointsOut   : '两分球未进',
    threePointsIn  : '三分球投中',
    threePointsOut : '三分球未进',
    freeThrowIn    : '罚球命中',
    freeThrowOut   : '罚球偏出'
  },
  type : {
    NoAnimation    : '暂无动画',
    coming         : '即将开始',
    notStarted     : '未开赛',
    firstHalf      : '上半场',
    secondHalf     : '下半场',
    fullTime       : '比赛结束',
    postponed      : '比赛延期',
    interruption   : '比赛中断',
    cut            : '比赛腰斩',
    cancel         : '比赛取消',
    error          : '比赛异常',
    undetermined   : '比赛待定',
    midfied        : '中场',
    first          : '第一节',
    second         : '第二节',
    third          : '第三节',
    fourth         : '第四节',
    firstEnd       : '第一节完',
    secondEnd      : '第二节完',
    thirdEnd       : '第三节完',
    fourthEnd      : '第四节完',
    overTime       : '加时',
    secondOverTime : '第二加时',
    thirdOverTime  : '第三加时'
  },
  position : {}
}
